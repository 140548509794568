import * as React from 'react'
import PropTypes from 'prop-types'
import { Script } from 'gatsby'
import loadable from '@loadable/component'

const LayoutInner = loadable(() => import('/src/components/Layout/LayoutInner'))
const PageHeader = loadable(() => import('/src/components/Structure/PageHeader'))
const Seo = loadable(() => import('/src/components/Structure/Seo'))
const Text = loadable(() => import('/src/components/PageBuilder/layouts/standard/Text'))

const StandardPage = ({ post }) => {
    const content = post.acfPageContent?.content

    const breadcrumb = {
        type: 'standard',
        pageParent: post.wpParent
    }

    return (
        <LayoutInner>
            {post.acfPage.addExternalScript && <Script src={post.acfPage.scriptSourceUrl} />}

            <Seo post={post} />

            <main>
                <article itemScope itemType="http://schema.org/Article">
                    <PageHeader
                        breadcrumb={breadcrumb}
                        pageTitle={post.title}
                        description={post.pageHeader.description}
                    />

                    {content && <Text content={content} />}
                </article>
            </main>
        </LayoutInner>
    )
}

StandardPage.propTypes = {
    /**
     * Post object
     */
    post: PropTypes.object.isRequired
}

export default StandardPage
